// Environment variables object
const ENVIRONMENT_VARIABLES = Object.freeze({
  env: {
    Region: 'us-west-2',
    Stage: 'beta',
    CloudFrontProtocol: 'https',
    CloudFrontDomain: 'd18uw8a6hpgzsx.cloudfront.net',
    CognitoUserPoolId: 'us-west-2_jpaGNi99f',
    CognitoIdentityPoolId: 'us-west-2:04496c4b-7c08-40b0-90c9-3fc6f64a9426',
    AuthenticationType: 'AMAZON_COGNITO_USER_POOLS',
    CognitoUserPoolWebClientId: '5h4hbc0sf6i9lk64hvsc3cuek3',
    CognitoDomain: 'xpt-application-beta.auth.us-west-2.amazoncognito.com',
    ApplicationDomain: 'https://beta.xpt.device.finance.amazon.dev',
    AwsAccountId: '777452703712',
    SNSNotificationARN: 'arn:aws:sns:us-west-2:777452703712:xPT-Slack-Notification-beta'
  }
});

export default ENVIRONMENT_VARIABLES;
